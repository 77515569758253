import { authModule, blockchainModule, metamaskModule } from "@/store";

declare global {
  interface Window {
    ethereum: any;
  }
}

declare module "vue/types/vue" {
  interface Vue {
    $ethereum: any;
  }
}

blockchainModule.refreshChainId();

if (window.ethereum) {
  // Retrieve connected wallet address
  if (metamaskModule && window.ethereum?.selectedAddress) {
    metamaskModule.setAccounts([window.ethereum.selectedAddress]);
  }

  // Refresh store on connection
  window.ethereum.on("connect", () => {
    setTimeout(() => {
      metamaskModule.setAccounts([window.ethereum.selectedAddress]);
    }, 0);
  });

  // Refresh store if chain changed
  window.ethereum.on("chainChanged", () => {
    blockchainModule.refreshChainId();
  });

  // Refresh store if accounts changed
  window.ethereum.on("accountsChanged", async (e: any) => {
    metamaskModule?.setAccounts(e);
    await authModule.logout();
    authModule?.refreshStatus();
  });
}
