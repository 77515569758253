





















import { Component, Vue, Watch } from "vue-property-decorator";
import { i18nModule, metamaskModule, usersModule } from "@/store";
import ToastsContainer from "@/components/ui/toasts/ToastsContainer.vue";
import { User } from "@/store/users/user";
import TransactionsManager from "@/components/ui/TransactionsManager.vue";

@Component({
  components: { TransactionsManager, ToastsContainer },
})
export default class App extends Vue {
  get me(): User | null {
    return usersModule.me;
  }

  mounted() {
    if (process.env.NODE_ENV !== "development") {
      metamaskModule.checkNetwork();
    }
  }

  @Watch("me.language", { immediate: true })
  onLanguageChanged(): void {
    if (this.me?.language?.length) {
      this.$i18n.locale = this.me.language;
      i18nModule.setLocale(this.me.language);
    }
  }
}
