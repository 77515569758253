










import { Component, Vue } from "vue-property-decorator";
import { sidebarModule } from "@/store";
import DashboardLayoutProductsMenu from "@/components/layouts/dashboard/DashboardLayoutProductsMenu.vue";
@Component({
  components: { DashboardLayoutProductsMenu },
})
export default class DashboardLayoutTopbarMenu extends Vue {
  openSidebar(): void {
    sidebarModule.setIsOpen(true);
  }
}
