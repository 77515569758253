import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "@/helpers/axios.helper";
import {
  blockchainModule,
  jimizzModule,
  metamaskModule,
  transactionsModule,
} from "@/store";
import { Contract, ethers } from "ethers";
import { round } from "@/helpers/round.helper";
import { Campaign } from "@/store/staking/campaign";
import { Stake } from "@/store/staking/stake";
import { StakingProgress } from "@/store/staking/staking-progress.enum";
import { StakePayload } from "@/store/staking/stake-payload";

@Module({
  name: "Staking",
  namespaced: true,
})
export default class StakingModule extends VuexModule {
  _isLoadingCampaigns = true;
  _isLoadingStakes = true;
  _campaigns: Campaign[] = [];
  _stakes: Stake[] = [];
  _sortStakeProperty = "endsOn";
  _sortStakeDirection = 1;
  _stakingProgress = StakingProgress.None;

  get isLoadingCampaigns(): boolean {
    return this._isLoadingCampaigns;
  }

  get isLoadingStakes(): boolean {
    return this._isLoadingStakes;
  }

  get campaigns(): Campaign[] {
    return this._campaigns;
  }

  get stakes(): Stake[] {
    return this._stakes;
  }

  get sortStakeProperty(): string {
    return this._sortStakeProperty;
  }

  get sortStakeDirection(): number {
    return this._sortStakeDirection;
  }

  get stakingProgress(): StakingProgress {
    return this._stakingProgress;
  }

  @Mutation
  setIsLoadingCampaigns(isLoadingCampaigns: boolean): void {
    this._isLoadingCampaigns = isLoadingCampaigns;
  }

  @Mutation
  setIsLoadingStakes(isLoadingStakes: boolean): void {
    this._isLoadingStakes = isLoadingStakes;
  }

  @Mutation
  setCampaigns(campaigns: Campaign[]): void {
    this._campaigns = campaigns;
  }

  @Mutation
  setStakes(stakes: Stake[]): void {
    this._stakes = stakes;
  }

  @Mutation
  setSortStakeProperty(sortStakeProperty: string): void {
    this._sortStakeProperty = sortStakeProperty;
  }

  @Mutation
  setSortStakeDirection(sortStakeDirection: number): void {
    this._sortStakeDirection = sortStakeDirection;
  }

  @Mutation
  setStakingProgress(stakingProgress: StakingProgress): void {
    this._stakingProgress = stakingProgress;
  }

  @Action({ rawError: true })
  async fetch(): Promise<void> {
    try {
      this.setIsLoadingCampaigns(true);
      this.setCampaigns([]);

      const campaigns: Campaign[] = await axios
        .get("/staking")
        .then((response) =>
          response.data.map((campaign: Campaign) => {
            const c = new Campaign(campaign);
            c.isLoading = true;
            c.rewardsPercentage = +campaign.rewardsPercentage;
            return c;
          })
        );

      campaigns.map((campaign: Campaign) => (campaign.isLoading = false));
      campaigns.sort((a, b) => {
        if (a.rewardsPercentage > b.rewardsPercentage) return 1;
        if (a.rewardsPercentage < b.rewardsPercentage) return -1;
        return 0;
      });
      this.setCampaigns(campaigns);
      this.setIsLoadingCampaigns(false);
    } catch (e) {
      this.setIsLoadingCampaigns(false);
      throw e.response?.data ?? e;
    }
  }

  @Action
  async getContract(campaign: Campaign): Promise<Contract | null> {
    try {
      return blockchainModule.getContract({
        contract: "StakingCampaign",
        address: campaign.address,
      });
    } catch (e) {
      return null;
    }
  }

  @Action({ rawError: true })
  async fetchStakes(account: string): Promise<void> {
    this.setIsLoadingStakes(true);
    this.setStakes([]);

    const stakes: Stake[] = [];

    await Promise.all(
      this.campaigns.map(async (campaign) => {
        try {
          const contract = await this.getContract(campaign);
          if (contract && metamaskModule.isGoodNetwork) {
            const [hasStake] = await contract.hasStake(account);
            if (hasStake) {
              const now = new Date();
              const campaignStakes = await contract.getStakes();
              for (const stake of campaignStakes) {
                const amount = round(+ethers.utils.formatEther(stake.amount));
                const rewards = round(+ethers.utils.formatEther(stake.rewards));
                const total = round(
                  +ethers.utils.formatEther(stake.amount.add(stake.rewards))
                );
                const endsOn = new Date(
                  +ethers.utils.formatUnits(stake.endsOn, 0) * 1000
                );
                const startedOn = new Date(
                  +ethers.utils.formatUnits(stake.startedOn, 0) * 1000
                );

                stakes.push({
                  campaign,
                  campaignTitle: campaign.title,
                  amount,
                  rewards,
                  total,
                  endsOn,
                  startedOn,
                  progression: Math.min(
                    round(((+now - +startedOn) / (+endsOn - +startedOn)) * 100),
                    100
                  ),
                });
              }
            }
          }
        } catch (e) {
          // silent
        }
      })
    );

    this.setIsLoadingStakes(false);
    this.setStakes(stakes);
    await this.sort();
  }

  @Action({ rawError: true })
  async sort(sortStakeProperty = ""): Promise<void> {
    let direction = this._sortStakeDirection;
    const property = sortStakeProperty.length
      ? sortStakeProperty
      : this._sortStakeProperty;

    if (sortStakeProperty === this._sortStakeProperty) {
      direction = 0 - direction;
    } else if (sortStakeProperty.length) {
      direction = 1;
    }

    this.setSortStakeProperty(property);
    this.setSortStakeDirection(direction);

    const stakes = this.stakes.sort((a, b) => {
      if (a[property] > b[property]) return direction;
      if (a[property] < b[property]) return direction * -1;
      return 0;
    });

    this.setStakes(stakes);
  }

  @Action
  async resetStakingProgress(): Promise<void> {
    this.setStakingProgress(StakingProgress.None);
  }

  @Action({ rawError: true })
  async stake(payload: StakePayload): Promise<void> {
    this.setStakingProgress(StakingProgress.None);

    try {
      const contract = await this.getContract(payload.campaign);
      if (contract) {
        const amount = ethers.utils.parseEther(payload.amount.toString());

        // Approve
        this.setStakingProgress(StakingProgress.Approving);
        await jimizzModule.approve({
          address: contract.address,
          amount,
        });
        this.setStakingProgress(StakingProgress.Approved);

        // Stake
        const tx = await contract.stake(amount);
        this.setStakingProgress(StakingProgress.Processing);
        await transactionsModule.addTransaction({
          hash: tx.hash,
          description: {
            label: "transactions.staking",
            vars: { n: payload.amount },
          },
        });
        await tx.wait();
        this.setStakingProgress(StakingProgress.Done);

        await this.fetch();
        this.setStakingProgress(StakingProgress.None);
      }
    } catch (e) {
      this.setStakingProgress(StakingProgress.None);
      throw e;
    }
  }

  @Action({ rawError: true })
  async withdraw(campaign: Campaign): Promise<void> {
    const contract = await this.getContract(campaign);
    if (contract) {
      const tx = await contract.withdraw();
      await transactionsModule.addTransaction({
        hash: tx.hash,
        description: { label: "transactions.staking-collect" },
      });
      await tx.wait();

      await this.fetch();
    }
  }
}
