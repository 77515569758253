import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import DashboardRoot from "@/views/dashboard/DashboardRoot.vue";
import { authModule } from "@/store";

Vue.use(VueRouter);

type RouteConfigExtended = RouteConfig & {
  isPublic?: boolean;
  needKyc?: boolean;
  children?: Array<RouteConfigExtended>;
};

const routes: Array<RouteConfigExtended> = [
  {
    path: "/",
    component: DashboardRoot,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/dashboard/Dashboard.vue"
          ),
      },
      {
        path: "staking",
        name: "Staking",
        component: () =>
          import(
            /* webpackChunkName: "staking" */ "@/views/dashboard/Staking.vue"
          ),
      },
      // {
      //   path: "x-savings",
      //   name: "X-Savings",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "x-savings" */ "@/views/dashboard/XSavings.vue"
      //     ),
      // },
      {
        path: "holder-assembly",
        name: "Holder Assembly",
        component: () =>
          import(
            /* webpackChunkName: "holder-assembly" */ "@/views/dashboard/HolderAssembly.vue"
          ),
      },
      {
        path: "account",
        name: "Account",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/dashboard/Account.vue"
          ),
      },
      {
        path: "transaction-history",
        name: "Transactions",
        component: () =>
          import(
            /* webpackChunkName: "transactions" */ "@/views/dashboard/Transactions.vue"
          ),
      },
      {
        path: "confirm-email/:token",
        name: "Confirm Email",
        component: () =>
          import(
            /* webpackChunkName: "confirm-email" */ "@/views/dashboard/ConfirmEmail.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to?.name === from?.name) {
    return next();
  }

  await authModule.refreshStatus();

  // Scroll to top of viewport
  setTimeout(() => window.scrollTo({ behavior: "smooth", top: 0 }));

  next();
});

export default router;
