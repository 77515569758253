import { Module, Mutation, VuexModule } from "vuex-module-decorators";

const LOCALSTORAGE_KEY = "jimizz::locale";

@Module({
  name: "I18n",
  namespaced: true,
})
export default class I18nModule extends VuexModule {
  locale: string =
    localStorage.getItem(LOCALSTORAGE_KEY) ||
    navigator.language?.split("-")[0] ||
    process.env.VUE_APP_I18N_LOCALE ||
    "en";

  get getLocale(): string {
    return this.locale;
  }

  @Mutation
  setLocale(locale: string): void {
    this.locale = locale;
    localStorage.setItem(LOCALSTORAGE_KEY, this.locale);
  }
}
