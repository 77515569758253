import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "@/helpers/axios.helper";
import { metamaskModule, usersModule } from "@/store";
import { ethers } from "ethers";
import { AuthStatus } from "@/store/auth/auth-status.enum";
import { ChallengeLoginResult } from "@/store/auth/challenge-login-result";

@Module({
  name: "Auth",
  namespaced: true,
})
export default class AuthModule extends VuexModule {
  _authStatus: AuthStatus = AuthStatus.None;

  get isAuthenticated(): boolean {
    return this._authStatus !== AuthStatus.None;
  }

  get authStatus(): AuthStatus {
    return this._authStatus;
  }

  @Mutation
  setAuthStatus(authStatus: AuthStatus): void {
    this._authStatus = authStatus;
  }

  @Action({ rawError: true })
  async login(): Promise<void> {
    if (!metamaskModule.account) {
      await metamaskModule.fetchAccounts();
      this.context.commit("setAuthStatus", AuthStatus.Metamask);
    }

    if (!metamaskModule.account) {
      throw new Error("User rejected the Metamask request");
    }

    if (this.authStatus !== AuthStatus.Full) {
      const result = await this.challengeLogin();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signature = await provider.getSigner().signMessage(result.message);
      const wallet = metamaskModule.account;
      await axios
        .post(`${process.env.VUE_APP_AUTH_URI}/auth/login`, {
          wallet,
          signature,
        })
        .then((response) => response.data);
      this.context.commit("setAuthStatus", AuthStatus.Full);

      await usersModule.fetchMe();
    }
  }

  @Action({ rawError: true })
  async refreshToken(): Promise<void> {
    try {
      await axios.get(`${process.env.VUE_APP_AUTH_URI}/auth/refresh`);
      this.context.commit("setAuthStatus", AuthStatus.Full);
    } catch (e) {
      this.context.commit("setAuthStatus", AuthStatus.None);
    }
  }

  @Action({ commit: "setAuthStatus" })
  async refreshStatus(): Promise<AuthStatus> {
    const account = metamaskModule.account;
    if (account) {
      await usersModule.fetchMe();
      if (usersModule.me) {
        return AuthStatus.Full;
      }

      return AuthStatus.Metamask;
    }

    return AuthStatus.None;
  }

  @Action({ commit: "setAuthStatus" })
  async logout(): Promise<AuthStatus> {
    try {
      await axios.get(`${process.env.VUE_APP_AUTH_URI}/auth/logout`);
    } catch (e) {
      // silent
    }

    return AuthStatus.None;
  }

  @Action({ rawError: true })
  private async challengeLogin(): Promise<ChallengeLoginResult> {
    try {
      const wallet = metamaskModule.account;
      return await axios
        .post(`${process.env.VUE_APP_AUTH_URI}/auth/challenge-login`, {
          wallet,
        })
        .then((response) => response.data);
    } catch (e) {
      this.context.commit("setAuthenticated", false);
      throw e.response?.data ?? e;
    }
  }

  @Action
  async confirmEmail(token: string): Promise<boolean> {
    const response: { success: boolean } = await axios
      .get(`${process.env.VUE_APP_AUTH_URI}/auth/confirm-email?token=${token}`)
      .then((response) => response.data);
    return response.success;
  }

  @Action
  async resendVerificationLink(email: string): Promise<void> {
    try {
      await axios.get(
        `${process.env.VUE_APP_AUTH_URI}/auth/resend-verification-link?email=${email}`
      );
    } catch (e) {
      throw e.response?.data ?? e;
    }
  }
}
