import { Store } from "vuex";
import { Transaction } from "@/store/transactions/transaction";
import { TransactionDescription } from "@/store/transactions/transaction-description";

export const transactionStorage = async (store: Store<unknown>) => {
  // Retrieve store transactions
  const serialized = localStorage.getItem("jimizz::transactions");
  if (serialized) {
    const txs = JSON.parse(serialized);
    const transactions = await Promise.all(
      txs.map(
        async (tx: {
          hash: string;
          status: number;
          description: TransactionDescription;
          timestamp: number;
        }) => {
          const transaction = new Transaction(
            store.getters["Blockchain/provider"],
            tx.hash,
            tx.description,
            {
              status: tx.status,
              timestamp: tx.timestamp,
            }
          );

          if (store.getters["Metamask/isGoodNetwork"]) {
            await transaction.init();
          }

          return transaction;
        }
      )
    );

    await store.commit("Transactions/setTransactions", transactions);
  }
};
