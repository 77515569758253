import { CampaignColor } from "@/store/staking/campaign-color.enum";
import { CampaignDto } from "@/store/staking/campaign.dto";

export class Campaign {
  title = "";
  address = "";
  rewardsPercentage = 0;
  lockupMonths = 0;
  isOpen = false;
  isRestricted?: boolean;
  isLoading?: boolean;
  color?: CampaignColor;

  constructor(campaign: CampaignDto) {
    this.title = campaign.title;
    this.address = campaign.address;
    this.rewardsPercentage = campaign.rewardsPercentage;
    this.lockupMonths = campaign.lockupMonths;
    this.isOpen = campaign.isOpen;
    this.color = campaign.color;
  }

  get image(): string {
    let filename = "special";
    if (
      this.title === "Basic" ||
      this.title === "Standard" ||
      this.title === "Premium"
    ) {
      filename = this.title.toLowerCase();
    }
    return `/img/staking/${filename}.webp`;
  }
}
