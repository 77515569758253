

























































































import { Component, Vue } from "vue-property-decorator";
import Dropdown from "@/components/ui/dropdown/Dropdown.vue";
import DropdownItem from "@/components/ui/dropdown/DropdownItem.vue";
import {
  authModule,
  jimizzModule,
  metamaskModule,
  sidebarModule,
} from "@/store";
import { round } from "@/helpers/round.helper";
import { CheckNetwork } from "@/helpers/decorators/check-network.decorator";
import { AuthStatus } from "@/store/auth/auth-status.enum";

@Component({
  components: { Dropdown, DropdownItem },
})
export default class DashboardLayoutTopbarAccount extends Vue {
  get isGoodNetwork(): boolean {
    return metamaskModule.isGoodNetwork;
  }

  get account(): string {
    return metamaskModule.account ?? "";
  }

  get shortAccount(): string {
    return [
      this.account.substring(0, 11),
      this.account.substring(this.account.length - 4),
    ].join("...");
  }

  get balance(): number {
    const balance = jimizzModule.balance;
    return round(balance);
  }

  get isFullyAuthenticated(): boolean {
    return authModule.authStatus === AuthStatus.Full;
  }

  @CheckNetwork
  async connect(): Promise<void> {
    await authModule.login();
  }

  async openSidebar(): Promise<void> {
    sidebarModule.setIsOpen(true);
  }

  async onDropdownOpen(): Promise<void> {
    try {
      await jimizzModule.fetchWalletBalance();
    } catch (e) {
      // TODO: display error
      console.log(e);
    }
  }

  async logout(): Promise<void> {
    await metamaskModule.logout();
    await authModule.logout();
  }
}
