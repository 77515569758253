import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./plugins/metamask.plugin";
import VueApexCharts from "vue-apexcharts";
import Flicking from "@egjs/vue-flicking";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://2599ae7e30a946508f1a88be59522224@o1429369.ingest.sentry.io/4503887712681984",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;

Vue.use(VueApexCharts);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("apexchart", VueApexCharts);

Vue.use(Flicking);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
