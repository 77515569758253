






















import { Component, Ref, Vue } from "vue-property-decorator";

@Component
export default class Dropdown extends Vue {
  isOpen = false;

  @Ref("base")
  base!: HTMLElement;

  @Ref("dark")
  dark!: HTMLElement;

  open(): void {
    this.isOpen = true;
    this.$emit("open");
    setTimeout(() => {
      document.addEventListener("click", this.handleClose);
    });
  }

  handleClose(e: MouseEvent): void {
    if (!this.base.contains(e.target as Node) || e.target === this.dark) {
      this.close();
    }
  }

  close(): void {
    this.isOpen = false;
    this.$emit("close");
    document.removeEventListener("click", this.handleClose);
  }

  destroyed(): void {
    document.removeEventListener("click", this.handleClose);
  }
}
