import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "@/helpers/axios.helper";
import { Article } from "@/store/news/article";

@Module({
  name: "News",
  namespaced: true,
})
export default class NewsModule extends VuexModule {
  private _fetching = false;
  _articles: Article[] = [];

  get fetching(): boolean {
    return this._fetching;
  }

  get articles(): Article[] {
    return this._articles;
  }

  @Mutation
  setFetching(fetching: boolean): void {
    this._fetching = fetching;
  }

  @Mutation
  setNews(articles: Article[]): void {
    this._articles = articles;
  }

  @Action({ rawError: true, commit: "setNews" })
  async fetch(): Promise<Article[]> {
    this.setNews([]);
    this.setFetching(true);

    return await axios.get("/news/").then((response) => {
      this.setFetching(false);
      return response.data;
    });
  }
}
