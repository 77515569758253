import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Toast } from "@/store/toasts/toast";
import { AddToastPayload } from "@/store/toasts/add-toast-payload";

@Module({
  name: "Toasts",
  namespaced: true,
})
export default class ToastsModule extends VuexModule {
  toasts: Toast[] = [];

  get getToasts(): Toast[] {
    return this.toasts;
  }

  @Mutation
  __setToasts(toasts: Toast[]): void {
    this.toasts = toasts;
  }

  @Action
  async addToast(payload: AddToastPayload): Promise<Toast> {
    const { type, ...options } = payload;
    const toast = new Toast(payload.type, options);
    this.toasts.push(toast);
    return toast;
  }

  @Action({ commit: "__setToasts" })
  async deleteToast(toast: Toast): Promise<Toast[]> {
    return this.toasts.filter((t) => t !== toast);
  }
}
