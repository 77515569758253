import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "@/helpers/axios.helper";
import { PriceHistory } from "@/store/chart/price-history";

@Module({
  name: "Chart",
  namespaced: true,
})
export default class ChartModule extends VuexModule {
  _isLoading = true;
  _history: PriceHistory[] = [];

  get isLoading(): boolean {
    return this._isLoading;
  }

  get history(): PriceHistory[] {
    return this._history;
  }

  @Mutation
  setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
  }

  @Mutation
  setHistory(history: PriceHistory[]): void {
    this._history = history;
  }

  @Action({ rawError: true })
  async fetch(): Promise<void> {
    try {
      this.setIsLoading(true);
      this.setHistory([]);

      const history: PriceHistory[] = await axios
        .get("/price-history")
        .then((response) =>
          response.data.map((ph: PriceHistory) => {
            ph.date = new Date(ph.date);
            return ph;
          })
        );

      this.setHistory(history);
    } catch (e) {
      throw e.response?.data ?? e;
    } finally {
      this.setIsLoading(false);
    }
  }
}
