import { TranslateResult } from "vue-i18n";
import { ToastType } from "@/store/toasts/toast-type.enum";
import { toastsModule } from "@/store";

export const DISPLAY_DURATION = 4000;
export const CLOSING_DURATION = 400;

export type ToastOptions = {
  title?: TranslateResult | string;
  text?: TranslateResult | string;
  isInfinite?: boolean;
  isClosable?: boolean;
  reversed?: boolean;
  onclick?: () => void;
};

export class Toast {
  private _isClosing = false;

  constructor(
    private readonly _type: ToastType,
    private readonly options: ToastOptions = {}
  ) {
    if (!this.options.isInfinite) {
      setTimeout(() => this.close(), DISPLAY_DURATION);
    }

    if (this.options.isClosable !== false) {
      this.options.isClosable = true;
    }
  }

  get type(): ToastType {
    return this._type;
  }

  get title(): TranslateResult | string {
    return this.options?.title ?? "";
  }

  get text(): TranslateResult | string | Array<TranslateResult | string> {
    return this.options?.text ?? "";
  }

  get isInfinite(): boolean {
    return !!this.options?.isInfinite;
  }

  get isClosable(): boolean {
    return !!this.options?.isClosable;
  }

  get onclick(): undefined | (() => void) {
    return this.options?.onclick;
  }

  get reversed(): boolean {
    return !!this.options.reversed;
  }

  get isClosing(): boolean {
    return this._isClosing;
  }

  close(): void {
    this._isClosing = true;
    setTimeout(() => toastsModule.deleteToast(this), CLOSING_DURATION);
  }
}
