import axios from "axios";
import { authModule, metamaskModule, usersModule } from "@/store";
import { AuthStatus } from "@/store/auth/auth-status.enum";

const instance = axios.create({
  baseURL: `${location.protocol}//${location.hostname}/api`,
  withCredentials: true,
  headers: { "Accept-Language": "en" },
});
instance.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers["Accept-Language"] = usersModule.me?.language ?? "en";
  return config;
});

instance.interceptors.response.use(
  (res) => res,
  async (error) => {
    const config = error.config;

    if (error.response?.status === 401 && !config._retry) {
      if (
        config.url !== `${process.env.VUE_APP_AUTH_URI}/auth/login` &&
        config.url !== `${process.env.VUE_APP_AUTH_URI}/auth/refresh` &&
        config.url !== `${process.env.VUE_APP_AUTH_URI}/auth/logout`
      ) {
        config._retry = true;

        const initialStatus = authModule.authStatus;

        if (metamaskModule.account) {
          authModule.setAuthStatus(AuthStatus.Metamask);
        } else {
          authModule.setAuthStatus(AuthStatus.None);
        }

        if (initialStatus === AuthStatus.Full) {
          await authModule.refreshToken();
        }

        if (authModule.authStatus === AuthStatus.Full) {
          return instance(config);
        } else {
          return Promise.reject("disconnected");
        }
      }
    }

    return Promise.reject(error.response?.data);
  }
);

export default instance;
