import Vue from "vue";
import Vuex from "vuex";
import { getModule } from "vuex-module-decorators";

import Auth from "@/store/auth/auth.module";
import I18n from "@/store/i18n/i18n.module";
import Toasts from "@/store/toasts/toasts.module";
import Users from "@/store/users/users.module";
import Blockchain from "@/store/blockchain/blockchain.module";
import Jimizz from "@/store/jimizz/jimizz.module";
import Staking from "@/store/staking/staking.module";
import Vesting from "@/store/vesting/vesting.module";
import Metamask from "@/store/metamask/metamask.module";
import News from "@/store/news/news.module";
import Surveys from "@/store/surveys/surveys.module";
import Sidebar from "@/store/sidebar/sidebar.module";
import Chart from "@/store/chart/chart.module";
import Transactions from "@/store/transactions/transactions.module";
import { transactionStorage } from "@/store/transactions/transaction-storage.plugin";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  modules: {
    Auth,
    I18n,
    Toasts,
    Users,
    Blockchain,
    Jimizz,
    Staking,
    Vesting,
    Metamask,
    News,
    Surveys,
    Sidebar,
    Chart,
    Transactions,
  },
  plugins: [transactionStorage],
});

export default store;
export const authModule = getModule(Auth, store);
export const i18nModule = getModule(I18n, store);
export const toastsModule = getModule(Toasts, store);
export const usersModule = getModule(Users, store);
export const blockchainModule = getModule(Blockchain, store);
export const jimizzModule = getModule(Jimizz, store);
export const stakingModule = getModule(Staking, store);
export const vestingModule = getModule(Vesting, store);
export const metamaskModule = getModule(Metamask, store);
export const newsModule = getModule(News, store);
export const surveysModule = getModule(Surveys, store);
export const sidebarModule = getModule(Sidebar, store);
export const chartModule = getModule(Chart, store);
export const transactionsModule = getModule(Transactions, store);
