import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { i18nModule } from "@/store";

Vue.use(VueI18n);

export type Locale = {
  code: string;
  name: string;
};
export const enabledLocales: Locale[] = [];

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
      enabledLocales.push({
        code: locale,
        name: locales(key)["locale_name"],
      });
    }
  });
  return messages;
}

const messages = loadLocaleMessages();
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
const locale = enabledLocales
  .map((locale) => locale.code)
  .includes(i18nModule.getLocale)
  ? i18nModule.getLocale
  : fallbackLocale;

export default new VueI18n({
  locale,
  fallbackLocale,
  messages,
  dateTimeFormats: {
    en: {
      long: {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
      short: {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      },
      time: {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h12",
      },
    },
    fr: {
      long: {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
      short: {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      },
      time: {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h24",
      },
    },
  },
});
