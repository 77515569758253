export enum Round {
  Floor,
  Ceil,
  Round,
}
export const round = (
  number: number,
  roundType: Round = Round.Floor,
  decimalPlaces = 2
) => {
  if (isNaN(number)) {
    return 0;
  }

  let fn = Math.floor;
  const co = 10 ** decimalPlaces;
  if (roundType === Round.Ceil) fn = Math.ceil;
  if (roundType === Round.Round) fn = Math.round;
  return fn(number * co) / co;
};
