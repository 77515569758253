import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Onboarding from "@metamask/onboarding";
import { ethers } from "ethers";
import { blockchainModule } from "@/store";

@Module({
  name: "Metamask",
  namespaced: true,
})
export default class MetamaskModule extends VuexModule {
  _accounts: string[] = [];
  onboarding?: Onboarding;

  get installed() {
    if (typeof window !== "undefined") {
      return Onboarding.isMetaMaskInstalled();
    } else {
      return null;
    }
  }

  get account(): string {
    return this.accounts[0] ?? null;
  }

  get accounts(): string[] {
    try {
      return this._accounts?.map((account) => ethers.utils.getAddress(account));
    } catch (e) {
      return [];
    }
  }

  get isGoodNetwork(): boolean {
    const env = process.env.VUE_APP_CHAIN_ID;
    return !(env && +env !== this.context.rootGetters["Blockchain/chainId"]);
  }

  @Mutation
  setAccounts(acc: string[]) {
    this._accounts = acc;
  }

  @Action({ commit: "setAccounts" })
  async fetchAccounts(): Promise<string[]> {
    if (!this.installed && !window.ethereum.isTrust) {
      await this.startOnboarding();
    } else {
      try {
        return await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } catch (e) {
        if (e?.code === -32002) {
          alert(
            "A request is already pending. Please check your Metamask extension."
          );
        }
      }
    }

    return [];
  }

  @Action
  async checkNetwork(): Promise<void> {
    const env = process.env.VUE_APP_CHAIN_ID;
    if (env && +env !== blockchainModule.chainId) {
      return this.switchNetwork();
    }
  }

  @Action
  async switchNetwork(): Promise<void> {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.VUE_APP_CHAIN_ID }],
      });

      await blockchainModule.refreshChainId();
    } catch (e) {
      if (e.code === 4902) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: process.env.VUE_APP_CHAIN_ID,
              rpcUrls: [process.env.VUE_APP_CHAIN_RPC_URL],
              chainName: process.env.VUE_APP_CHAIN_NAME,
              nativeCurrency: {
                name: process.env.VUE_APP_CHAIN_CURRENCY_NAME,
                symbol: process.env.VUE_APP_CHAIN_CURRENCY_SYMBOL,
                decimals: 18,
              },
              blockExplorerUrls: [process.env.VUE_APP_CHAIN_EXPLORER],
            },
          ],
        });

        await blockchainModule.refreshChainId();
      } else if (e?.code === -32002) {
        alert(
          "A request is already pending. Please check your Metamask extension."
        );
      } else {
        throw e;
      }
    }
  }

  @Action
  async startOnboarding(): Promise<void> {
    if (!this.onboarding) {
      this.onboarding = new Onboarding();
    }
    this.onboarding.startOnboarding();
  }

  @Action
  async stopOnboarding(): Promise<void> {
    this.onboarding?.stopOnboarding();
  }

  @Action({ commit: "setAccounts" })
  async logout(): Promise<string[]> {
    return [];
  }
}
