
















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { sidebarModule } from "@/store";
import DashboardLayoutProductsMenu from "@/components/layouts/dashboard/DashboardLayoutProductsMenu.vue";
@Component({
  components: { DashboardLayoutProductsMenu },
})
export default class DashboardLayoutSidebar extends Vue {
  get isOpen(): boolean {
    return sidebarModule.isOpen;
  }

  get helpCenterLink(): string {
    return `https://www.jimizz.com/${
      this.$i18n.locale === "en" ? "en/" : ""
    }help`;
  }

  closeSidebar(): void {
    sidebarModule.setIsOpen(false);
  }

  @Watch("$route")
  onRouteChange(): void {
    this.closeSidebar();
  }

  @Watch("isOpen")
  onOpenChange(): void {
    document.documentElement.classList[this.isOpen ? "add" : "remove"](
      "sidebar-open"
    );
  }
}
