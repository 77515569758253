import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Contract, providers } from "ethers";

@Module({
  name: "Blockchain",
  namespaced: true,
})
export default class BlockchainModule extends VuexModule {
  _chainId: number | null = null;

  get provider(): providers.Web3Provider {
    return new providers.Web3Provider(window.ethereum, "any");
  }

  get chainId(): number | null {
    return this._chainId;
  }

  @Mutation
  setChainId(chainId: number): void {
    this._chainId = chainId;
  }

  @Action
  async getContract({
    contract,
    address,
  }: {
    contract: string;
    address: string;
  }): Promise<Contract> {
    const artifact = await import(`@/artifacts/${contract}`);
    return new Contract(
      address,
      artifact.default.abi,
      this.provider?.getSigner() ?? this.provider
    );
  }

  @Action
  async getJimizz(): Promise<Contract> {
    const artifact = await import(`@/artifacts/ERC20`);
    return new Contract(
      process.env.VUE_APP_JIMIZZ_ADDRESS ?? "",
      artifact.default.abi,
      this.provider?.getSigner() ?? this.provider
    );
  }

  @Action({ rawError: true, commit: "setChainId" })
  async refreshChainId(): Promise<number> {
    const { chainId } = await this.provider?.getNetwork();
    return chainId;
  }
}
