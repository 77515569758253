import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "@/helpers/axios.helper";
import { User } from "@/store/users/user";

@Module({
  name: "Users",
  namespaced: true,
})
export default class UsersModule extends VuexModule {
  me: User | null = null;

  get getMe(): User | null {
    return this.me;
  }

  @Mutation
  setMe(me: User | null): void {
    this.me = me;
  }

  @Action({ commit: "setMe" })
  async fetchMe(): Promise<User | null> {
    try {
      return await axios.get(`/users/me`).then((response) => response.data);
    } catch (e) {
      return null;
    }
  }

  @Action({ rawError: true })
  async updateMe(user: User): Promise<void> {
    try {
      const data = { ...user };
      if (!data.email?.length) {
        delete data.email;
      }

      const me = await axios
        .put(`${process.env.VUE_APP_AUTH_URI}/users/me`, data)
        .then((response) => response.data);
      this.setMe(me);
    } catch (e) {
      throw e.message ?? e;
    }
  }

  @Action({ rawError: true })
  async fetchStakingBalance(wallet: string): Promise<number> {
    try {
      const data = await axios
        .get(`/users/staking-balance?wallet=${wallet}`)
        .then((response) => response.data);
      return data.balance ?? 0;
    } catch (e) {
      return 0;
    }
  }
}
