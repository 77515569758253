import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  name: "Sidebar",
  namespaced: true,
})
export default class SidebarModule extends VuexModule {
  private _isOpen = false;

  get isOpen(): boolean {
    return this._isOpen;
  }

  @Mutation
  setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen;
  }
}
