

















import { Component, Vue } from "vue-property-decorator";
import DashboardLayoutTopbarMenu from "@/components/layouts/dashboard/DashboardLayoutTopbarMenu.vue";
import DashboardLayoutTopbarAccount from "@/components/layouts/dashboard/DashboardLayoutTopbarAccount.vue";

@Component({
  components: {
    DashboardLayoutTopbarAccount,
    DashboardLayoutTopbarMenu,
  },
})
export default class DashboardLayoutTopbar extends Vue {}
