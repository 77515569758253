import { createDecorator } from "vue-class-component";
import { metamaskModule } from "@/store";

export const CheckNetwork = createDecorator((options, key) => {
  if (options?.methods) {
    const method = options.methods[key];
    options.methods[key] = async function wrapper(...args) {
      try {
        await metamaskModule.checkNetwork();
        method.apply(this, args);
      } catch (e) {
        // silent
      }
    };
  }
});
