import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { blockchainModule, metamaskModule } from "@/store";
import { Transaction } from "@/store/transactions/transaction";
import { AddTransactionPayload } from "@/store/transactions/add-transaction-payload";
import { TransactionStatus } from "@/store/transactions/transaction-status.enum";

@Module({
  name: "Transactions",
  namespaced: true,
})
export default class TransactionsModule extends VuexModule {
  _transactions: Transaction[] = [];

  get transactions(): Transaction[] {
    return this._transactions.sort((a, b) => {
      if (a.timestamp > b.timestamp) return 1;
      if (a.timestamp < b.timestamp) return -1;
      return 0;
    });
  }

  get pendingTransactions(): Transaction[] {
    return this._transactions.filter(
      (t) => t.status === TransactionStatus.Pending
    );
  }

  get hasPendingTransaction(): boolean {
    return !!this.pendingTransactions.length;
  }

  @Mutation
  setTransactions(transactions: Transaction[]): void {
    this._transactions = transactions;
  }

  @Action
  async initTransactions(): Promise<void> {
    await Promise.all(this._transactions.map(async (tx) => await tx.init()));
  }

  @Action
  async addTransaction(payload: AddTransactionPayload): Promise<void> {
    const tx = new Transaction(
      blockchainModule.provider,
      payload.hash,
      payload.description
    );
    if (metamaskModule.isGoodNetwork) {
      await tx.init();
    }

    this._transactions.push(tx);

    localStorage.setItem(
      "jimizz::transactions",
      JSON.stringify(this._transactions.map((t) => t.store()))
    );
  }
}
