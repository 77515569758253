import { TransactionResponse } from "@ethersproject/abstract-provider";
import { providers } from "ethers";
import { TransactionStatus } from "@/store/transactions/transaction-status.enum";
import { TransactionDescription } from "@/store/transactions/transaction-description";

type Options = {
  status?: TransactionStatus;
  description?: TransactionDescription;
  timestamp?: number;
};

export class Transaction {
  private _tx?: TransactionResponse;
  private _initialized = false;
  private _status: TransactionStatus;
  private readonly _timestamp = Date.now();

  constructor(
    private readonly _provider: providers.Web3Provider,
    private readonly _hash: string,
    private readonly _description: TransactionDescription,
    options?: Options
  ) {
    this._status = options?.status ?? TransactionStatus.Created;

    if (options?.timestamp) {
      this._timestamp = options.timestamp;
    }
  }

  get timestamp(): number {
    return this._timestamp;
  }

  get description(): TransactionDescription {
    return this._description;
  }

  get hash(): string {
    return this._hash;
  }

  get status(): TransactionStatus {
    return this._status;
  }

  get link(): string {
    return [process.env.VUE_APP_CHAIN_EXPLORER, "tx/", this._hash].join("");
  }

  async init(): Promise<void> {
    if (!this._initialized) {
      this._initialized = true;

      this._tx = await this._provider.getTransaction(this._hash);
      this._tx.wait(TransactionStatus.Pending).then(() => {
        setTimeout(() => {
          if (this._status !== TransactionStatus.Confirmed) {
            this._status = TransactionStatus.Pending;
          }
        }, 100);
      });

      this._tx.wait(TransactionStatus.Confirmed).then(() => {
        this._status = TransactionStatus.Confirmed;
      });
    }
  }

  store(): {
    timestamp: number;
    hash: string;
    status: number;
    description?: TransactionDescription;
  } {
    return {
      timestamp: this._timestamp,
      hash: this._hash,
      status: +this._status,
      description: this.description,
    };
  }
}
