import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "@/helpers/axios.helper";
import { Survey } from "@/store/surveys/survey";

@Module({
  name: "Surveys",
  namespaced: true,
})
export default class SurveysModule extends VuexModule {
  private _fetching = false;
  private _surveys: Survey[] = [];

  get fetching(): boolean {
    return this._fetching;
  }

  get surveys(): Survey[] {
    return this._surveys;
  }

  get lastSurvey(): Survey {
    return [...this._surveys].sort((a, b) => {
      if (a.userChoice && !b.userChoice) return 1;
      else if (!a.userChoice && b.userChoice) return -1;
      else if (!a.userChoice && !b.userChoice)
        return +new Date(a.start) - +new Date(b.start);
      else return +new Date(b.start) - +new Date(a.start);
    })[0];
  }

  @Mutation
  setFetching(fetching: boolean): void {
    this._fetching = fetching;
  }

  @Mutation
  setSurveys(surveys: Survey[]): void {
    this._surveys = surveys;
  }

  @Action({ rawError: true })
  async vote({
    surveyId,
    choiceId,
  }: {
    surveyId: string;
    choiceId: string;
  }): Promise<void> {
    const survey = await axios
      .post(`/surveys/${surveyId}/vote`, {
        choice: choiceId,
      })
      .then((response) => response.data);

    const surveys = [...this._surveys];
    surveys[surveys.findIndex((s) => s.id === survey.id)] = survey;
    this.setSurveys([...surveys]);
  }

  @Action({ rawError: true, commit: "setSurveys" })
  async fetchAll(): Promise<Survey[]> {
    this.setFetching(true);

    return await axios.get("/surveys/").then((response) => {
      this.setFetching(false);
      return response.data;
    });
  }
}
