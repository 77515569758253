












import { Component, Vue } from "vue-property-decorator";
import ToastComponent from "@/components/ui/toasts/ToastComponent.vue";
import { toastsModule } from "@/store";
import { Toast } from "@/store/toasts/toast";

@Component({
  components: { ToastComponent },
})
export default class ToastsContainer extends Vue {
  get toasts(): Toast[] {
    return toastsModule.getToasts;
  }
}
