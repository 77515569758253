







import { Component, Vue } from "vue-property-decorator";
import ToastsContainer from "@/components/ui/toasts/ToastsContainer.vue";
import DashboardLayout from "@/components/layouts/dashboard/DashboardLayout.vue";

@Component({
  components: { DashboardLayout, ToastsContainer },
})
export default class DashboardRoot extends Vue {}
