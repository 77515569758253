































import { Component, Vue } from "vue-property-decorator";

@Component
export default class DashboardLayoutProductsMenu extends Vue {
  get jimizzComLink(): string {
    return `https://www.jimizz.com/${this.$i18n.locale === "en" ? "en/" : ""}`;
  }

  get marketplaceLink(): string {
    return `https://marketplace.jimizz.com/${
      this.$i18n.locale === "en" ? "en/" : ""
    }`;
  }

  get blogLink(): string {
    return `${this.jimizzComLink}blog/`;
  }

  get helpCenterLink(): string {
    return `${this.jimizzComLink}help/`;
  }
}
