













import { Component, Prop, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import Dropdown from "@/components/ui/dropdown/Dropdown.vue";

@Component
export default class DropdownItem extends Vue {
  @Prop({ type: Boolean })
  header!: boolean;

  @Prop({ type: Boolean })
  divider!: boolean;

  @Prop()
  to!: string | RawLocation;

  click(): void {
    if (this.to) {
      this.$router.push(this.to).catch(() => null);
    }

    (this.$parent as Dropdown).close();
    this.$emit("click");
  }
}
