


import { Component, Vue, Watch } from "vue-property-decorator";
import { metamaskModule, toastsModule, transactionsModule } from "@/store";
import Dropdown from "@/components/ui/dropdown/Dropdown.vue";
import DropdownItem from "@/components/ui/dropdown/DropdownItem.vue";
import { Toast } from "@/store/toasts/toast";
import { ToastType } from "@/store/toasts/toast-type.enum";

@Component({
  components: { DropdownItem, Dropdown },
})
export default class TransactionsManager extends Vue {
  pendingToast: Toast | null = null;

  get account(): string {
    return metamaskModule.account;
  }

  get isGoodNetwork(): boolean {
    return metamaskModule.isGoodNetwork;
  }

  get hasPendingTransaction(): boolean {
    return transactionsModule.hasPendingTransaction;
  }

  async mounted(): Promise<void> {
    await transactionsModule.initTransactions();
    window.addEventListener("beforeunload", this.alertUser);
  }

  destroyed(): void {
    window.removeEventListener("beforeunload", this.alertUser);
  }

  /**
   * Alert user when leaving the page if there are pending transactions
   * @param {Event} e
   */
  alertUser(e: Event): void | string {
    // Alert user when leaving the page if there are pending transactions
    if (this.hasPendingTransaction) {
      e.preventDefault();
      const message = "Pending transactions";
      e.returnValue = false;
      return message;
    }
  }

  @Watch("account", { immediate: true })
  @Watch("isGoodNetwork", { immediate: true })
  refresh(): void {
    transactionsModule.initTransactions();
  }

  @Watch("hasPendingTransaction", { immediate: true })
  async onPendingTransaction(): Promise<void> {
    if (this.hasPendingTransaction && !this.pendingToast) {
      const tx = transactionsModule.pendingTransactions[0];
      this.pendingToast = await toastsModule.addToast({
        type: ToastType.Pending,
        title: this.$t("transactions.pending"),
        text: this.$t(tx.description.label, {
          ...tx.description.vars,
        }),
        isInfinite: true,
        isClosable: false,
      });
    } else if (this.pendingToast) {
      this.pendingToast.close();
      this.pendingToast = null;
    }
  }
}
