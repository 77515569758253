
























import { Component, Prop, Vue } from "vue-property-decorator";
import { Toast } from "@/store/toasts/toast";

@Component
export default class ToastComponent extends Vue {
  @Prop()
  toast!: Toast;

  onclick(): void {
    if (this.toast.onclick) {
      this.toast.onclick.call(null);
    }

    if (this.toast.isClosable) {
      this.toast.close();
    }
  }
}
