













import { Component, Vue } from "vue-property-decorator";
import DashboardLayoutTopbar from "@/components/layouts/dashboard/DashboardLayoutTopbar.vue";
import DashboardLayoutSidebar from "@/components/layouts/dashboard/DashboardLayoutSidebar.vue";

@Component({
  components: { DashboardLayoutTopbar, DashboardLayoutSidebar },
})
export default class DashboardLayout extends Vue {}
