import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { blockchainModule, metamaskModule, transactionsModule } from "@/store";
import { Contract, ethers } from "ethers";
import { Schedule } from "@/store/vesting/schedule";

@Module({
  name: "Vesting",
  namespaced: true,
})
export default class VestingModule extends VuexModule {
  _address: string | null = null;
  _contract: Contract | null = null;
  _schedules: Schedule[] = [];
  _availableAmount = 0;

  get address(): string | null {
    return this._address;
  }

  get contract(): Contract | null {
    return this._contract;
  }

  get schedules(): Schedule[] {
    return this._schedules;
  }

  get availableAmount(): number {
    return this._availableAmount;
  }

  @Mutation
  setAddress(address: string): void {
    this._address = address;
  }

  @Mutation
  setContract(contract: Contract): void {
    this._contract = contract;
  }

  @Mutation
  setSchedules(schedules: Schedule[]): void {
    this._schedules = schedules;
  }

  @Mutation
  setAvailableAmount(availableAmount: number): void {
    this._availableAmount = availableAmount;
  }

  @Action
  async reset(): Promise<void> {
    this.context.commit("setAddress", null);
    this.context.commit("setContract", null);
    this.context.commit("setSchedules", []);
    this.context.commit("setAvailableAmount", 0);
  }

  @Action
  async init(address: string): Promise<void> {
    if (metamaskModule.isGoodNetwork) {
      const contract = await blockchainModule.getContract({
        contract: "GradedVesting",
        address: address,
      });

      this.context.commit("setAddress", address);
      this.context.commit("setContract", contract);

      await this.refresh();
    }
  }

  @Action
  async refresh(): Promise<void> {
    await this.fetchSchedules();
    await this.fetchAvailableAmount();
  }

  @Action
  async fetchInitialBalance(): Promise<number> {
    const contract = this.context.getters["contract"];
    if (contract) {
      const result = await contract.initialBalance();
      return +ethers.utils.formatEther(result);
    }

    return 0;
  }

  @Action({ commit: "setSchedules" })
  async fetchSchedules(): Promise<Schedule[]> {
    const contract = this.context.getters["contract"];
    if (contract) {
      const schedules: Schedule[] = [];
      const result = await contract.getSchedules();
      for (const schedule of result) {
        schedules.push({
          percentage: schedule.percentage,
          date: new Date(+schedule.timestamp * 1000),
        });
      }

      return schedules;
    }

    return [];
  }

  @Action({ commit: "setAvailableAmount" })
  async fetchAvailableAmount(): Promise<number> {
    const contract = this.context.getters["contract"];
    if (contract) {
      const amount = await contract.getAvailableAmount();
      return +ethers.utils.formatEther(amount);
    }

    return 0;
  }

  @Action
  async collect(): Promise<void> {
    const contract = this.context.getters["contract"];
    if (contract) {
      const tx = await contract.collect();
      await transactionsModule.addTransaction({
        hash: tx.hash,
        description: { label: "transactions.vesting-collect" },
      });
      await tx.wait();
    }
  }
}
