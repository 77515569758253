import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ethers } from "ethers";
import {
  blockchainModule,
  metamaskModule,
  transactionsModule,
  usersModule,
} from "@/store";
import { ApprovePayload } from "@/store/jimizz/approve-payload";

@Module({
  name: "Jimizz",
  namespaced: true,
})
export default class JimizzModule extends VuexModule {
  _balance = 0;
  _stakingBalance = 0;

  get balance(): number {
    return this._balance;
  }

  get stakingBalance(): number {
    return this._stakingBalance;
  }

  get totalBalance(): number {
    return this._balance + this._stakingBalance;
  }

  @Mutation
  setBalance(balance: number): void {
    this._balance = balance;
  }

  @Mutation
  setStakingBalance(balance: number): void {
    this._stakingBalance = balance;
  }

  @Action({ commit: "setBalance" })
  async fetchWalletBalance(): Promise<number> {
    if (metamaskModule.account && metamaskModule.isGoodNetwork) {
      return await this.fetchBalance(metamaskModule.account);
    }
    return 0;
  }

  @Action({ commit: "setStakingBalance" })
  async fetchStakingBalance(): Promise<number> {
    if (metamaskModule.account && metamaskModule.isGoodNetwork) {
      return await usersModule.fetchStakingBalance(metamaskModule.account);
    }
    return 0;
  }

  @Action({ rawError: true })
  async fetchBalance(address: string): Promise<number> {
    const jimizz = await blockchainModule.getJimizz();
    const balance = await jimizz.balanceOf(address);
    return +ethers.utils.formatEther(balance);
  }

  @Action({ rawError: true })
  async getAllowance(address: string): Promise<ethers.BigNumber> {
    const jimizz = await blockchainModule.getJimizz();
    return jimizz.allowance(metamaskModule.account, address);
  }

  @Action({ rawError: true })
  async approve(payload: ApprovePayload): Promise<void> {
    const jimizz = await blockchainModule.getJimizz();
    const tx = await jimizz.approve(payload.address, payload.amount);
    await transactionsModule.addTransaction({
      hash: tx.hash,
      description: {
        label: "transactions.approve",
        vars: { n: ethers.utils.formatEther(payload.amount) },
      },
    });
    await tx.wait();
  }
}
